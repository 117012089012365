

import Axios from "@/Api/Config/basics"
import {
    HeadType,
    BodyType,
    getUserCountType,
    getSchoolMonthUserCountType,
    issueNoticeAdminType,
    deleteNoticeAdminType,
    addSchoolAdminType,
    updateSchoolAdminType,
    updateNoticeAdminType,
    updateBannerAdminType,
    addModuleAdminType,
    updateModuleAdminType,
    delModuleAdminType,
    getModuleAdminType,
    addSchoolModuleAdminType
} from "@/Api/Platform/interfaceType"

/**                    新用户 */
// 获取 用户 | 总用户 | 新用户
export function getUserCount( headers:HeadType,body:getUserCountType={} ):Promise<any>{
    return (
        Axios({
            url:"/app/user/queryNewUserCount",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 每个学校 用户 | 总用户 | 新用户 量
export function getSchoolUserCount( headers:HeadType,body:BodyType ):Promise<any>{
    return (
        Axios({
            url:"/app/user/eachSchoolUser",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 每个学校 用户 | 总用户 | 新用户 量
export function getSchoolMonthUserCount( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/app/user/eachMonthUser",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 每个学校 用户 | 总用户 | 新用户 量
export function getSchoolDayUserCount( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/app/user/everyDayUser",
            method:"POST",
            headers,
            data:body
        })
    )
}



                /**           公告管理  */
// GetNotice 获取公告列表
// GetNoticeSuc 公告详情
// 发布公告
export function issueNoticeAdmin( headers:HeadType,body:issueNoticeAdminType ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/cms/addCms",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 发布公告
export function updateNoticeAdmin( headers:HeadType,body:updateNoticeAdminType ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/cms/updateCms",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除 公告
export function deleteNoticeAdmin( headers:HeadType,body:deleteNoticeAdminType ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/cms/delete",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 这里




                /**            修改学校列表 */
// GetSchool 查询学校列表
// 修改学校
export function updateSchoolAdmin( headers:HeadType,body:updateSchoolAdminType ):Promise<any>{
    return (
        Axios({
            url:"/school/modify",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加学校
export function addSchoolAdmin( headers:HeadType,body:addSchoolAdminType ):Promise<any>{
    return (
        Axios({
            url:"/school/create",
            method:"POST",
            headers,
            data:body
        })
    )
}






// 删除学校
export function deleteSchoolAdmin( headers:HeadType,body:deleteNoticeAdminType ):Promise<any>{
    return (
        Axios({
            url:"/school/remove",
            method:"POST",
            headers,
            data:body
        })
    )
}



                /**            全平台banner */
// GetBanner
// 添加 banner图
export function addBannerAdmin( headers:HeadType,body:addSchoolAdminType ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/addBanner",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改 banner图
export function updateBannerAdmin( headers:HeadType,body:updateBannerAdminType ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/updateBanner",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除 banner图
export function delBannerAdmin( headers:HeadType,body:deleteNoticeAdminType ):Promise<any>{
    return (
        Axios({
            url:"/app/banner/deleteBanner",
            method:"POST",
            headers,
            data:body
        })
    )
}



/**                         九宫格     */

// 添加 九宫格图
export function addModuleAdmin( headers:HeadType,body:addModuleAdminType ):Promise<any>{
    return (
        Axios({
            url:"/school/app/menu/create",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改 九宫格图
export function updateModuleAdmin( headers:HeadType,body:updateModuleAdminType ):Promise<any>{
    return (
        Axios({
            url:"/school/app/menu/modify",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除 九宫格图
export function delModuleAdmin( headers:HeadType,body:delModuleAdminType ):Promise<any>{
    return (
        Axios({
            url:"/school/app/menu/removeMenu",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 九宫格图
export function getModuleAdmin( headers:HeadType,body:getModuleAdminType ):Promise<any>{
    return (
        Axios({
            url:"/school/app/menu/getList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加学校模板添加 九宫格
export function addSchoolModuleAdmin( headers:HeadType,body:addSchoolModuleAdminType ):Promise<any>{
    return (
        Axios({
            url:"/schoolMenu/create",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加学校模板添加 九宫格
export function delSchoolModuleAdmin( headers:HeadType,body:delModuleAdminType ):Promise<any>{
    return (
        Axios({
            url:"/schoolMenu/remove",
            method:"POST",
            headers,
            data:body
        })
    )
}
