import Storage from "@/util/Storage";
import {filterToArray} from "@/views/PlatformAdmin/Util";
import {getSchoolMonthUserCount} from "@/Api/Platform/bascs";


export function getMonthUserNum(upData:any ={}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getSchoolMonthUserCount({ userId,token },upData).then(res=>{
        return {
            rawList:res.data,
            list:filterToArray(res.data,"无","dateTime","userNumber","userTotal")
        }
    }))
}
