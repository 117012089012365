

export const TitleList = [
    {
        width: "33",
        value: "日期",
        after: true
    },{
        width: "33",
        value: "新增",
        after: true
    },{
        width: "33",
        value: "累计用户",
        after: false
    },
]


export const testList = [
    [
        {
            value: "无",
        }, {
            value: "0",
        }, {
            value: "0",
        },
    ]
]
